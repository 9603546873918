import React from 'react';
import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    height: '100%',
    widht: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '80px 16px 16px 16px',
});

const WishlistUnauthenticatedEmpty = ({ content }) => {
    const [css] = useStyletron();

    return (
        <Wrapper>
            <OverlayContent content={content.wishlistUnauthenticatedEmptyContent} />
            <div className={css({ display: 'flex', gap: '12px', width: '100%' })}>
                <ThemeButton $style={{ width: '100%' }} as={Link} to="/sign-in">
                    Log in
                </ThemeButton>
                <ThemeButton $style={{ width: '100%' }} as={Link} to="/create-account">
                    Create account
                </ThemeButton>
            </div>
        </Wrapper>
    );
};

WishlistUnauthenticatedEmpty.propTypes = {
    content: PropTypes.object,
};

export default WishlistUnauthenticatedEmpty;
